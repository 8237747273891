<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 유해위험기계기구 기본정보 -->
      <c-card title="유해위험기계기구 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && equipInfo.plantName" class="col-3">
              <c-label-text title="사업장" :value="equipInfo.plantName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryClassificationName" class="col-3">
            <!-- 기계분류 -->
            <c-label-text title="기계분류" :value="equipInfo.machineryClassificationName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryName" class="col-3">
            <!-- 기계명 -->
            <c-label-text title="기계명" :value="equipInfo.machineryName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.itemNo" class="col-3">
            <!-- item No -->
            <c-label-text title="item No" :value="equipInfo.itemNo"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 개선 목록 -->
      <c-table
        ref="tableImpr"
        title="개선 목록"
        :columns="gridImpr.columns"
        :data="equipInfo.equipmentImproveModels"
        :merge="gridImpr.merge"
        :gridHeight="gridImpr.height"
        selection="multiple"
        rowKey="equipImproveId"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
              <c-btn v-if="editable && equipInfo.equipmentImproveModels.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
              <c-btn
              v-if="editable && equipInfo.equipmentImproveModels.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="equipInfo.equipmentImproveModels"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveImprove"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              tableKey="equipImproveId"
              :ibmTaskTypeCd="props.row.equipTypeCd === '1' ? 'ITT0000055' : 'ITT0000050'"
              :ibmTaskUnderTypeCd="props.row.equipTypeCd === '1' ? 'ITTU000085' : 'ITTU000075'"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-cause-prevention',
  props: {
    equipInfo: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        equipmentCd: '',
        plantName: '',
        machineryClassificationName: '',
        itemNo: '',
        machineryName: '',
        emplacementMonth: '',
        inspectionSubjectFlagName: '',
        inspectionCycleName: '',
        gradeName: '',
        resultName: '',
        departmentDeptName: '',
        departmentUserName: '',
        inspectionKindName: '',
        equipmentImproveModels: []
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'locationDetail',
            field: 'locationDetail',
            // 세부위치
            label: '세부위치',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            required: true,
            name: 'findings',
            field: 'findings',
            // 발견사항
            label: '발견사항',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:20%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improveMeasures',
                field: 'improveMeasures',
                // 개선대책
                label: '개선대책',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
          {
            name: 'equipTypeName',
            field: 'equipTypeName',
            // 구분
            label: '구분',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
        ],
        
        data: [],
        height: '700px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['locationDetail', 'findings'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.hhm.hazard.hazardImprove.insert.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardImprove.delete.url;
      
    },
    // 개선창 닫기 후
    imprChange() {
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    addItem() {
      this.equipInfo.equipmentImproveModels.splice(0, 0, {
        hhmHazardousMachineryId: this.equipInfo.hhmHazardousMachineryId,
        equipImproveId: uid(),
        locationDetail: '',
        equipTypeCd: '2',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        improveMeasures: '',
        findings: '',
        editFlag: 'C',
        fileNm: '',
      })
    },
    removeItem() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveImprove() {
      if (this.$comm.validTable(this.gridImpr.columns, this.equipInfo.equipmentImproveModels)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
    },
  }
};
</script>